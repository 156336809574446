CMS = CMS || {};

const openConfirmationModals = () => {
  const loads = ["open_models"];

  const openModals = () => {
    var modal = document.getElementById('js-modal');
    var closeModalButton = document.getElementById('js-close-confirmation-modal');
    var confirmSubmitButton = document.getElementById('js-confirm-submit');
    var currentFormId = null;

    document.querySelectorAll('.js-open-confirmation-modal').forEach(function (button) {
      button.addEventListener('click', function () {
        currentFormId = button.getAttribute('data-form-id');
        modal.classList.remove('hidden');
      });
    });

    if (closeModalButton) {
      closeModalButton.addEventListener('click', function () {
        modal.classList.add('hidden');
      });
    }

    if (confirmSubmitButton) {
      confirmSubmitButton.addEventListener('click', function () {
        if (currentFormId) {
          document.getElementById(currentFormId).submit();
        }
      });

    }
  }

  return {
    loads: loads,
    open_models: openModals,
  };
};

CMS.openConfirmationModals = openConfirmationModals();

/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import "core-js/stable";
import "regenerator-runtime/runtime";

// This will be needed in rails 6 to make rails ujs works with javascript compiled by webpack
//right now rails 5.x rails-ujs exposes Rails variable globally. It is removed in rails 6 though
//  Then we need to include 'import Rails from "rails-ujs"' in every es6 module we are using rails-ujs in it.
// import Rails from "rails-ujs";
// // Rails.start();

import "activestorage";
import "../js/analytics";
import "../js/asset_uploader";
import "../js/asset_uploader_for_asset_model";
import "../js/image_uploader_for_asset_model";
import "../js/component";
import "../js/component_instance_configurator";
import "../js/ui_pattern_radio_button";
import "../js/banner_picker";
import "../js/configuration_form";
import "../js/dealerships";
// import "../js/drag_drop_functionality";
import "../js/loading_component";
import "../js/partials_injector";
import "../js/page_configurator";
import "../js/page_details_form";
import "../js/form_confirm_submission";
import "../js/form_disable_save_before_edit";
import "../js/wiki_help_bar";
import "../js/home_stock_search_config_form";
import "../js/vehicle_feature_form";
import "../js/dealer_locator_cta_form";
import "../js/radio_buttons";
import "../js/search_list";
import "../js/stock_promo_labels";
import "../js/text_field_counter";
import "../js/vehicle_models";
import "../js/vehicle_trims";
import "../js/visibility_toggler";
import "../js/specials";
import "../js/populate_secondary_select";
import "../js/construct_button_url_using_select";
import "../js/choices";
import "../js/toggle_rule_group_rules";
import "../js/equals_and_not_equals_rule_forms";
import "../js/search_bar";
import "../js/automatically_initiate_choices_select";
import "../js/testimonials";
import "../js/media_type_radio_button";
import "../js/page_section_type_radio_button";
import "../js/font_type_radio_button";
import "../js/check_box_div_show_hide";
import "../js/colour_picker";
import "../js/config_form";
import "../js/otp_resend_counter";
import "../js/open_confirmation_modals";
import Stock from "../js/stock";
import "../js/asset_categories";
import "../js/web_notification";
import "../js/dashboard_data";
import "../js/select_submit_step_for_dynamic_form";
import "../js/stock_location_states";
document.addEventListener("turbolinks:load", () => {
  CMS.init();
  global.CMS = CMS;
  CMS.Stock = new Stock();
});

/* eslint-disable no-undef */
/* global App */
CMS = CMS || {};

// Creating the desired object using the revealing module pattern
const web_notification = () => {
  // populting the loads array with the function required to run in the "turbolinks:load" listener.
  const loads = ["initialise_web_notification"];

  const initialise_web_notification = () => {
    const gatsby_element = document.getElementById("js-web-notification-channel");
    if (gatsby_element) {
      const dealershipId = gatsby_element.value;
      if (!dealershipId) return;
      App.web_notifications = App.cable.subscriptions.create(
        {
          channel: "WebNotificationsChannel",
          dealership_id: dealershipId,
        },
        {
          received: function (data) {
            var toastElem = document.querySelector(".js-toast-wrapper");
            var toastHeading = document.querySelector(".js-tsh-heading");
            var toastBody = document.querySelector(".js-toast-section-body");
            var menuBuildWrapper = document.querySelector(".js-menu-basic-wrapper");
            var menuBuildBtn = document.querySelector(".js-build-trigger");

            if (toastElem == null || toastHeading == null) return;

            var crossButton = document.querySelector(".tsh-close");

            if (crossButton) {
              crossButton.addEventListener("click", function () {
                toastElem.classList.add("hide");
              });
            }

            var currentUserId = document.getElementById("js-toast-user-id").value;
            var userName = data["userId"] == currentUserId ? "you" : data["user"];

            if (data["duration"] != null) {
              var durationSecs = parseInt(data["duration"]);
              var durationMoments = moment.duration(durationSecs, "seconds");
              if (durationSecs > 60)
                var duration = moment.utc(durationMoments.asMilliseconds()).format("m [minutes] s [seconds]");
              else duration = moment.utc(durationMoments.asMilliseconds()).format("s [seconds]");
            }

            // eslint-disable-next-line no-undef
            var createdAt = moment(parseInt(data["createdAt"])).fromNow();

            switch (data["status"]) {
              case "ongoing":
                menuBuildBtn.setAttribute("data-balloon-pos", "left");
                menuBuildWrapper.classList.add("disabled");
                toastElem.classList.remove("hide");
                toastElem.classList.remove("fail");
                toastElem.classList.remove("success");
                toastElem.classList.add("pending");
                toastBody.classList.add("hide");
                toastHeading.innerHTML =
                  "Waiting for the " + data["buildType"] + " triggered by " + userName + " to finish - " + createdAt;
                break;
              case "success":
                toastElem.classList.add("animate");
                menuBuildBtn.setAttribute("data-balloon-pos", "none");
                menuBuildWrapper.classList.remove("disabled");
                setTimeout(() => {
                  toastElem.classList.remove("hide");
                  toastElem.classList.remove("fail");
                  toastElem.classList.remove("pending");
                  toastElem.classList.add("success");
                  toastBody.classList.remove("hide");
                  fillToastData(toastHeading, duration, userName, createdAt, data, "succeeded");
                }, 500);
                break;
              case "failed":
                toastElem.classList.add("animate");
                menuBuildBtn.setAttribute("data-balloon-pos", "none");
                menuBuildWrapper.classList.remove("disabled");
                setTimeout(() => {
                  toastElem.classList.remove("hide");
                  toastElem.classList.remove("success");
                  toastElem.classList.remove("pending");
                  toastElem.classList.add("fail");
                  toastBody.classList.remove("hide");
                  fillToastData(toastHeading, duration, userName, createdAt, data, "failed");
                }, 500);
                break;
              case "timeout":
                toastElem.classList.add("animate");
                menuBuildBtn.setAttribute("data-balloon-pos", "none");
                menuBuildWrapper.classList.remove("disabled");
                setTimeout(() => {
                  toastElem.classList.remove("hide");
                  toastElem.classList.remove("success");
                  toastElem.classList.remove("pending");
                  toastElem.classList.add("fail");
                  toastBody.classList.remove("hide");
                  fillToastData(toastHeading, duration, userName, createdAt, data, "timed out");
                }, 500);
                break;
              default:
              // code block
            }
          },
        }
      );
    }
    const brand_element = document.getElementById("js-brand-web-notification-channel");
    if (brand_element) {
      const brandId = brand_element.value;
      if (!brandId) return;
      App.web_notifications = App.cable.subscriptions.create(
        {
          channel: "BrandWebNotificationsChannel",
          brand_id: brandId,
        },
        {
          received: function (data) {
            const url = new URL(window.location.href);
            switch (data["status"]) {
              case "ongoing":
                console.log(
                  "Waiting for the report of type:" +
                    data["reportType"] +
                    " triggered by " +
                    data["userName"] +
                    " to finish"
                );
                break;
              case "success":
                if (url.pathname.endsWith("/dashboard/brand_reports")) {
                  window.location.reload();
                }
                console.log(
                  "Report generation for the type: " +
                    data["reportType"] +
                    " triggered by " +
                    data["userName"] +
                    " is successful"
                );
                break;
              case "failed":
                if (url.pathname.endsWith("/dashboard/brand_reports")) {
                  window.location.reload();
                }
                console.log(
                  "Report generation for the type: " +
                    data["reportType"] +
                    " triggered by " +
                    data["userName"] +
                    " has failed"
                );
                break;
              default:
              // code block
            }
          },
        }
      );
    }
    const group_element = document.getElementById("js-group-web-notification-channel");
    if (group_element) {
      const groupId = group_element.value;
      if (!groupId) return;
      App.web_notifications = App.cable.subscriptions.create(
        {
          channel: "GroupWebNotificationsChannel",
          group_id: groupId,
        },
        {
          received: function (data) {
            const url = new URL(window.location.href);
            switch (data["status"]) {
              case "ongoing":
                console.log(
                  "Waiting for the report of type:" +
                  data["reportType"] +
                  " triggered by " +
                  data["userName"] +
                  " to finish"
                );
                break;
              case "success":
                if (url.pathname.endsWith("/dashboard/group_reports")) {
                  window.location.reload();
                }
                console.log(
                  "Report generation for the type: " +
                  data["reportType"] +
                  " triggered by " +
                  data["userName"] +
                  " is successful"
                );
                break;
              case "failed":
                if (url.pathname.endsWith("/dashboard/group_reports")) {
                  window.location.reload();
                }
                console.log(
                  "Report generation for the type: " +
                  data["reportType"] +
                  " triggered by " +
                  data["userName"] +
                  " has failed"
                );
                break;
              default:
              // code block
            }
          },
        }
      );
    }
  };

  const fillToastData = (toastHeading, duration, userName, createdAt, data, event) => {
    toastHeading.innerHTML = data["buildType"] + " triggered by " + userName + " has " + event + " after " + duration;
    document.getElementById("js-toast-gatsy-build-duration").innerHTML = duration;
    document.getElementById("js-toast-gatsy-build-user").innerHTML = userName;
    document.getElementById("js-toast-gatsy-build-created").innerHTML = createdAt;
    document.getElementById("js-toast-gatsy-build-url").href = data["deployPreviewUrl"];
    var logsDiv = document.getElementById("js-toast-gatsy-build-logs");
    if (logsDiv) logsDiv.href = data["logsUrl"];
  };

  // exposing public mehtods and attributes
  return {
    loads: loads, // loads should be public all the time.
    initialise_web_notification: initialise_web_notification,
  };
};

// attaching the dealership object to the CMS object
CMS.web_notification = web_notification();
